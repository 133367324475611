<template>
  <transition name="slide-fade"
    mode="out-in"
    v-if="carregouMetadados">
    <router-view></router-view>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';

export default {
  name: 'ApuracaoAcaoRouter',
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
      'getCentroCustoMetadado',
      'getDivisaoMetadado',
      'getFornecedorMetadado',
    ]),
    ...generateComputed('ACAO', [
      'canAccessPage',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),

    carregouMetadados() {
      const acaoMetadados = this.getAcaoMetadado;
      const produtoMetadados = this.getProdutoMetadado;
      const cecoMetadados = this.getCentroCustoMetadado;
      const divisaoMetadados = this.getDivisaoMetadado;
      const fornecedorMetadados = this.getFornecedorMetadado;

      if (acaoMetadados != null
        && produtoMetadados != null
        && cecoMetadados != null
        && divisaoMetadados != null
        && fornecedorMetadados != null) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setProdutoMetadado',
      'setCentroCustoMetadado',
      'setDivisaoMetadado',
      'setFornecedorMetadado',
    ]),
    accessForbidden() {
      if (!this.canAccessPage) {
        if (!this.getAllRoles
            || !this.getAllRoles.filter((el) => el.startsWith('ACESSO_CLIENTE')).length) {
          this.$router.push({ name: 'inicio' });
          this.$toast(this.$t('message.acesso_negado'));
        }
      }
    },
  },
  beforeMount() {
    this.setCentroCustoMetadado({
      resource: this.metadadoResource.definicaoCentroCusto,
    });
    this.setProdutoMetadado({
      resource: this.metadadoResource.listarProduto,
    });
    this.setDivisaoMetadado({
      resource: this.metadadoResource.listarDivisao,
    });
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setFornecedorMetadado({
      resource: this.metadadoResource.listarFornecedor,
    });
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
